import type { LocaleObject } from "@nuxtjs/i18n";
import type Locale from "../lib/types/Locale";

declare module "@nuxtjs/i18n" {
  // Disabled for declaration merging
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface LocaleObject extends Locale {}
}

const locales: LocaleObject[] = [
  {
    code: "en",
    iso: "en",
    name: "Global",
    countries:
      "ae,af,ag,ai,am,an,ao,aq,ar,as,aw,az,bb,bd,bf,bh,bi,bj,bl,bm,bn,bo,bq,br,bs,bt,bv,bw,bz,cc,cd,cf,cg,ci,ck,cl,cm,cn,co,cr,cu,cv,cw,cx,dj,dm,do,dz,ec,eg,eh,er,et,fj,fk,fm,ga,gd,gf,gh,gl,gm,gn,gp,gq,gs,gt,gu,gw,gy,hk,hm,hn,ht,id,il,in,io,iq,ir,jm,jo,jp,ke,kg,kh,ki,km,kn,kp,kr,kw,ky,kz,la,lb,lc,lk,lr,ls,ly,ma,mf,mg,mh,ml,mm,mn,mo,mp,mq,mr,mu,mv,mw,mx,my,mz,na,nc,ne,nf,ng,ni,np,nr,nu,om,pa,pe,pf,pg,ph,pk,pm,pn,pr,ps,pw,py,qa,re,rw,sa,sb,sc,sd,sg,sh,sl,sn,so,sr,ss,st,sv,sx,sy,sz,tc,td,tf,tg,th,tj,tk,tl,tm,tn,to,tr,tt,tv,tw,tz,ug,um,uy,uz,vc,ve,vg,vi,vn,vu,wf,ws,xk,ye,yt,zm,zw",
    default: true,
    includeHrefCountries: false,
    customHrefLangs: "",
    fakerLocale: "en",
    prefixLocale: true,
    bookingEngineLocale: "en_US",
    agentPortalLocale: "en_US",
    isRegionSelectorVisible: true,
    currencyCode: "usd",
    enduranceRegion: "ww",
    distributeBookings: true,
    isOnHoldEnabled: true,
    currencyLocale: "en-US",
  },
  {
    code: "au",
    iso: "en-au",
    name: "Australia",
    countries: "au",
    includeHrefCountries: false,
    customHrefLangs: "",
    fakerLocale: "en_AU",
    prefixLocale: true,
    bookingEngineLocale: "en_AU",
    agentPortalLocale: "en_AU",
    isRegionSelectorVisible: true,
    currencyCode: "aud",
    distributeBookings: false,
    isOnHoldEnabled: true,
    currencyLocale: "en-AU",
  },
  {
    code: "ca",
    iso: "en-ca",
    name: "Canada",
    countries: "ca",
    includeHrefCountries: false,
    customHrefLangs: "",
    fakerLocale: "en_CA",
    prefixLocale: true,
    bookingEngineLocale: "en_CA",
    agentPortalLocale: "en_CA",
    isRegionSelectorVisible: true,
    currencyCode: "cad",
    distributeBookings: false,
    isOnHoldEnabled: true,
    currencyLocale: "en-CA",
  },
  {
    code: "ie",
    iso: "en-ie",
    name: "Ireland",
    countries: "ie",
    includeHrefCountries: false,
    customHrefLangs: "",
    fakerLocale: "en_IE",
    prefixLocale: true,
    bookingEngineLocale: "en_IE",
    agentPortalLocale: "en_IE",
    isRegionSelectorVisible: true,
    currencyCode: "eur",
    distributeBookings: false,
    isOnHoldEnabled: true,
    currencyLocale: "en-IE",
  },
  {
    code: "nz",
    iso: "en-nz",
    name: "New Zealand",
    countries: "nz",
    includeHrefCountries: false,
    customHrefLangs: "",
    fakerLocale: "en",
    prefixLocale: true,
    bookingEngineLocale: "en_NZ",
    agentPortalLocale: "en_NZ",
    isRegionSelectorVisible: true,
    currencyCode: "nzd",
    distributeBookings: false,
    isOnHoldEnabled: true,
    currencyLocale: "en-NZ",
  },
  {
    code: "uk",
    iso: "en-gb",
    name: "United Kingdom",
    countries: "gb,gg,gi,im,je",
    includeHrefCountries: false,
    customHrefLangs: "",
    fakerLocale: "en_GB",
    prefixLocale: true,
    bookingEngineLocale: "en_GB",
    agentPortalLocale: "en_GB",
    isRegionSelectorVisible: true,
    currencyCode: "gbp",
    enduranceRegion: "gb",
    distributeBookings: false,
    isOnHoldEnabled: true,
    currencyLocale: "en-GB",
  },
  {
    code: "us",
    iso: "en-us",
    name: "United States",
    countries: "us",
    includeHrefCountries: false,
    customHrefLangs: "",
    fakerLocale: "en_US",
    prefixLocale: true,
    bookingEngineLocale: "en_US",
    agentPortalLocale: "en_US",
    isRegionSelectorVisible: true,
    currencyCode: "usd",
    distributeBookings: false,
    isOnHoldEnabled: true,
    currencyLocale: "en-US",
  },
  {
    code: "ch",
    iso: "en-ch",
    name: "Switzerland",
    countries: "ch",
    includeHrefCountries: false,
    customHrefLangs: "fr-ch,de-ch",
    fakerLocale: "en",
    prefixLocale: true,
    bookingEngineLocale: "en_CH",
    agentPortalLocale: "ch_CH",
    isRegionSelectorVisible: true,
    currencyCode: "chf",
    distributeBookings: false,
    isOnHoldEnabled: true,
    currencyLocale: "ch-CH",
  },
  {
    code: "za",
    iso: "en-za",
    name: "South Africa",
    countries: "za",
    includeHrefCountries: false,
    customHrefLangs: "",
    fakerLocale: "en_ZA",
    prefixLocale: true,
    bookingEngineLocale: "en_ZA",
    agentPortalLocale: "za_ZA",
    isRegionSelectorVisible: true,
    currencyCode: "zar",
    distributeBookings: false,
    isOnHoldEnabled: true,
    currencyLocale: "za-ZA",
  },
  {
    code: "be",
    iso: "en-be",
    name: "Belgium",
    countries: "be",
    includeHrefCountries: false,
    customHrefLangs: "",
    fakerLocale: "en",
    prefixLocale: true,
    bookingEngineLocale: "en_BE",
    agentPortalLocale: "en_IE",
    isRegionSelectorVisible: true,
    currencyCode: "eur",
    distributeBookings: false,
    isOnHoldEnabled: false,
    currencyLocale: "en-BE",
  },
  {
    code: "nl",
    iso: "en-nl",
    name: "Netherlands",
    countries: "nl",
    includeHrefCountries: false,
    customHrefLangs: "",
    fakerLocale: "nl",
    prefixLocale: true,
    bookingEngineLocale: "en_NL",
    agentPortalLocale: "en_NL",
    isRegionSelectorVisible: true,
    currencyCode: "eur",
    distributeBookings: false,
    isOnHoldEnabled: false,
    currencyLocale: "en-NL",
  },
  {
    code: "eu",
    iso: "en-eu",
    name: "Europe",
    countries:
      "ad,al,at,ax,ba,bg,by,cy,cz,dk,ee,es,fi,fo,fr,ge,gr,hr,hu,is,it,li,lt,lu,lv,mc,md,me,mk,ms,no,pl,pt,ro,rs,ru,se,si,sj,sk,sm,ua,va",
    includeHrefCountries: true,
    customHrefLangs: "",
    fakerLocale: "en",
    prefixLocale: true,
    bookingEngineLocale: "en_EU",
    agentPortalLocale: "en_IE",
    isRegionSelectorVisible: true,
    currencyCode: "eur",
    distributeBookings: true,
    isOnHoldEnabled: true,
    currencyLocale: "en-IE",
  },
  {
    code: "mt",
    iso: "en-mt",
    name: "Malta",
    countries: "mt",
    includeHrefCountries: false,
    customHrefLangs: "",
    fakerLocale: "en",
    prefixLocale: true,
    bookingEngineLocale: "en_MT",
    agentPortalLocale: "en_IE",
    isRegionSelectorVisible: true,
    currencyCode: "eur",
    distributeBookings: false,
    isOnHoldEnabled: false,
    currencyLocale: "en-MT",
  },
  {
    code: "de",
    iso: "en-de",
    name: "Germany",
    countries: "de",
    includeHrefCountries: false,
    customHrefLangs: "de-de",
    fakerLocale: "en",
    prefixLocale: true,
    bookingEngineLocale: "de_DE",
    agentPortalLocale: "de_DE",
    isRegionSelectorVisible: true,
    currencyCode: "eur",
    distributeBookings: false,
    isOnHoldEnabled: true,
    currencyLocale: "de-DE",
  },
];

export default locales;
