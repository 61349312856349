import { ContactUsFormFields } from "components/ContactUsForm/Props";
import { FormstackForm } from "~/lib/types/Formstack/FormstackForm";
import { SubscriptionFormFields } from "~/components/Subscription/Props";
import { WtphFormFields } from "~~/components/WTPH/WtphFormFields";

export const subscriptionForm: FormstackForm<SubscriptionFormFields> = {
  uat: {
    id: "5359155",
    first_name: "147755357",
    last_name: "147755371",
    country_of_residence: "147755394",
    email: "147755399",
    privacy_policy: "148690142",
    submitted_datetime: "149763742",
    source_type: "152467973",
    source: "158937051",
  },
  prd: {
    id: "5389446",
    first_name: "149008746",
    last_name: "149008747",
    country_of_residence: "149008748",
    email: "149008749",
    privacy_policy: "149008750",
    submitted_datetime: "149772729",
    source_type: "152541671",
    source: "159397076",
  },
};

export const wtphForm: FormstackForm<WtphFormFields> = {
  uat: {
    id: "5391174",
    wtph: "149369457",
    description: "149370423",
    pageUrl: "149370655",
    pageTitle: "149370656",
    pageType: "149370667",
  },
  prd: {
    id: "5403071",
    wtph: "149547005",
    description: "149547006",
    pageUrl: "149547007",
    pageTitle: "149547008",
    pageType: "149547009",
  },
};

export const contactUsForm: FormstackForm<ContactUsFormFields> = {
  uat: {
    id: "5434106",
    is_travel_agent: "150849850",
    first_name: "150849133",
    last_name: "150849135",
    store_email: "150960081",
    country_of_residence: "151188280",
    contact_method: "150849188",
    email: "150849154",
    country_code: "150849158",
    phone_number: "150849155",
    booking_reference: "150882241",
    enquiry_type: "150849190",
    message: "150849195",
    subscribed: "151230176",
    privacy_agreed: "151179108",
    submitted_datetime: "150960261",
    currency_code: "151391685",
    marketing_region: "151391702",
    sales_region: "151391701",
    subject_email_template: "151741789",
    country_name_email_template: "151850858",
    store_email_email_template: "151851252",
    user_type_email_template: "151853104",
    enquiry_type_email_template: "151851279",
    isSalesforceEnquiry: "155192285",
    salesforce_contact_id: "153443246",
  },
  prd: {
    id: "5524810",
    is_travel_agent: "154647394",
    first_name: "154647395",
    last_name: "154647396",
    store_email: "154647397",
    country_of_residence: "154647398",
    contact_method: "154647399",
    email: "154647400",
    country_code: "154647401",
    phone_number: "154647402",
    booking_reference: "154647404",
    enquiry_type: "154647405",
    message: "154647406",
    subscribed: "154647407",
    privacy_agreed: "154647408",
    submitted_datetime: "154647409",
    currency_code: "154647410",
    marketing_region: "154647411",
    sales_region: "154647412",
    subject_email_template: "154647413",
    country_name_email_template: "154647414",
    store_email_email_template: "154647415",
    user_type_email_template: "154647417",
    enquiry_type_email_template: "154647416",
    isSalesforceEnquiry: "155193383",
    salesforce_contact_id: "154647419",
  },
};
