import { Country } from "~~/lib/types/Country";
import { MarketingRegion } from "~~/lib/types/MarketingRegion";

export const countries: Country[] = [
  {
    iso2Code: "au",
    name: "Australia",
    bookingEngineLocale: "en_AU",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+61",
  },
  {
    iso2Code: "ca",
    name: "Canada",
    bookingEngineLocale: "en_CA",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+1",
  },
  {
    iso2Code: "ie",
    name: "Ireland",
    bookingEngineLocale: "en_IE",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+353",
  },
  {
    iso2Code: "nz",
    name: "New Zealand",
    bookingEngineLocale: "en_NZ",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+64",
  },
  {
    iso2Code: "ch",
    name: "Switzerland",
    bookingEngineLocale: "ch_CH",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+41",
  },
  {
    iso2Code: "gb",
    name: "United Kingdom",
    bookingEngineLocale: "en_GB",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+44",
  },
  {
    iso2Code: "us",
    name: "United States",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+1",
  },
  {
    iso2Code: "af",
    name: "Afghanistan",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+93",
  },
  {
    iso2Code: "ax",
    name: "Aland Islands",
    bookingEngineLocale: "en_IE",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+358",
  },
  {
    iso2Code: "al",
    name: "Albania",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+355",
  },
  {
    iso2Code: "dz",
    name: "Algeria",
    bookingEngineLocale: "en_DZ",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+213",
  },
  {
    iso2Code: "as",
    name: "American Samoa",
    bookingEngineLocale: "en_AS",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+1684",
  },
  {
    iso2Code: "ad",
    name: "Andorra",
    bookingEngineLocale: "en_AD",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+376",
  },
  {
    iso2Code: "ao",
    name: "Angola",
    bookingEngineLocale: "en_AO",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+244",
  },
  {
    iso2Code: "ai",
    name: "Anguilla",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+1264",
  },
  {
    iso2Code: "aq",
    name: "Antarctica",
    bookingEngineLocale: "en_AQ",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+672",
  },
  {
    iso2Code: "ag",
    name: "Antigua and Barbuda",
    bookingEngineLocale: "en_AG",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+1268",
  },
  {
    iso2Code: "ar",
    name: "Argentina",
    bookingEngineLocale: "en_AR",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+54",
  },
  {
    iso2Code: "am",
    name: "Armenia",
    bookingEngineLocale: "en_AM",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+374",
  },
  {
    iso2Code: "aw",
    name: "Aruba",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+297",
  },
  {
    iso2Code: "at",
    name: "Austria",
    bookingEngineLocale: "en_AT",
    phoneCode: "+43",
  },
  {
    iso2Code: "az",
    name: "Azerbaijan",
    bookingEngineLocale: "en_AZ",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+994",
  },
  {
    iso2Code: "bs",
    name: "Bahamas",
    bookingEngineLocale: "en_BS",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+1242",
  },
  {
    iso2Code: "bh",
    name: "Bahrain",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+973",
  },
  {
    iso2Code: "bd",
    name: "Bangladesh",
    bookingEngineLocale: "en_BD",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+880",
  },
  {
    iso2Code: "bb",
    name: "Barbados",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+1246",
  },
  {
    iso2Code: "by",
    name: "Belarus",
    bookingEngineLocale: "en_IE",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+375",
  },
  {
    iso2Code: "be",
    name: "Belgium",
    bookingEngineLocale: "en_BE",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+32",
  },
  {
    iso2Code: "bz",
    name: "Belize",
    bookingEngineLocale: "en_BZ",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+501",
  },
  {
    iso2Code: "bj",
    name: "Benin",
    bookingEngineLocale: "en_BJ",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+229",
  },
  {
    iso2Code: "bm",
    name: "Bermuda",
    bookingEngineLocale: "en_BM",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+1441",
  },
  {
    iso2Code: "bt",
    name: "Bhutan",
    bookingEngineLocale: "en_BT",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+975",
  },
  {
    iso2Code: "bo",
    name: "Bolivia",
    bookingEngineLocale: "en_BO",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+591",
  },
  {
    iso2Code: "ba",
    name: "Bosnia and Herzegovina",
    bookingEngineLocale: "en_BA",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+387",
  },
  {
    iso2Code: "bw",
    name: "Botswana",
    bookingEngineLocale: "en_BW",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+267",
  },
  {
    iso2Code: "bv",
    name: "Bouvet Island",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+55",
  },
  {
    iso2Code: "br",
    name: "Brazil",
    bookingEngineLocale: "en_BR",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+55",
  },
  {
    iso2Code: "io",
    name: "British Indian Ocean Territory",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+246",
  },
  {
    iso2Code: "vg",
    name: "British Virgin Islands",
    bookingEngineLocale: "en_VG",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+1284",
  },
  {
    iso2Code: "bn",
    name: "Brunei",
    bookingEngineLocale: "en_BN",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+673",
  },
  {
    iso2Code: "bg",
    name: "Bulgaria",
    bookingEngineLocale: "en_BG",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+359",
  },
  {
    iso2Code: "bf",
    name: "Burkina Faso",
    bookingEngineLocale: "en_BF",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+226",
  },
  {
    iso2Code: "bi",
    name: "Burundi",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+257",
  },
  {
    iso2Code: "kh",
    name: "Cambodia",
    bookingEngineLocale: "en_KH",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+855",
  },
  {
    iso2Code: "cm",
    name: "Cameroon",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+237",
  },
  {
    iso2Code: "cv",
    name: "Cape Verde",
    bookingEngineLocale: "en_CV",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+238",
  },
  {
    iso2Code: "bq",
    name: "Caribbean Netherlands",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+599",
  },
  {
    iso2Code: "ky",
    name: "Cayman Islands",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+1345",
  },
  {
    iso2Code: "cf",
    name: "Central African Republic",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+236",
  },
  {
    iso2Code: "td",
    name: "Chad",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+235",
  },
  {
    iso2Code: "cl",
    name: "Chile",
    bookingEngineLocale: "en_CL",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+56",
  },
  {
    iso2Code: "cn",
    name: "China",
    bookingEngineLocale: "en_CN",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+86",
  },
  {
    iso2Code: "cx",
    name: "Christmas Island",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+61",
  },
  {
    iso2Code: "cc",
    name: "Cocos (Keeling) Islands",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+61",
  },
  {
    iso2Code: "co",
    name: "Colombia",
    bookingEngineLocale: "en_CO",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+57",
  },
  {
    iso2Code: "km",
    name: "Comoros",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+269",
  },
  {
    iso2Code: "cd",
    name: "Congo (Kinshasa)",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+243",
  },
  {
    iso2Code: "ck",
    name: "Cook Islands",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+682",
  },
  {
    iso2Code: "cr",
    name: "Costa Rica",
    bookingEngineLocale: "en_CR",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+506",
  },
  {
    iso2Code: "hr",
    name: "Croatia",
    bookingEngineLocale: "en_HR",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+385",
  },
  {
    iso2Code: "cu",
    name: "Cuba",
    bookingEngineLocale: "en_CU",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+53",
  },
  {
    iso2Code: "cw",
    name: "Curacao",
    bookingEngineLocale: "en_CW",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+599",
  },
  {
    iso2Code: "cy",
    name: "Cyprus",
    bookingEngineLocale: "en_CY",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+357",
  },
  {
    iso2Code: "cz",
    name: "Czech Republic",
    bookingEngineLocale: "en_CZ",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+420",
  },
  {
    iso2Code: "dk",
    name: "Denmark",
    bookingEngineLocale: "en_DK",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+45",
  },
  {
    iso2Code: "dj",
    name: "Djibouti",
    bookingEngineLocale: "en_DJ",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+253",
  },
  {
    iso2Code: "dm",
    name: "Dominica",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+1767",
  },
  {
    iso2Code: "do",
    name: "Dominican Republic",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+1809",
  },
  {
    iso2Code: "ec",
    name: "Ecuador",
    bookingEngineLocale: "en_EC",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+593",
  },
  {
    iso2Code: "eg",
    name: "Egypt",
    bookingEngineLocale: "en_EG",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+20",
  },
  {
    iso2Code: "sv",
    name: "El Salvador",
    bookingEngineLocale: "en_SV",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+503",
  },
  {
    iso2Code: "gq",
    name: "Equatorial Guinea",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+240",
  },
  {
    iso2Code: "er",
    name: "Eritrea",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+291",
  },
  {
    iso2Code: "ee",
    name: "Estonia",
    bookingEngineLocale: "en_EE",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+372",
  },
  {
    iso2Code: "sz",
    name: "Eswatini (Swaziland)",
    bookingEngineLocale: "en_SZ",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+268",
  },
  {
    iso2Code: "et",
    name: "Ethiopia",
    bookingEngineLocale: "en_ET",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+251",
  },
  {
    iso2Code: "fk",
    name: "Falkland Islands",
    bookingEngineLocale: "en_FK",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+500",
  },
  {
    iso2Code: "fo",
    name: "Faroe Islands",
    bookingEngineLocale: "en_IE",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+298",
  },
  {
    iso2Code: "fj",
    name: "Fiji",
    bookingEngineLocale: "en_FJ",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+679",
  },
  {
    iso2Code: "fi",
    name: "Finland",
    bookingEngineLocale: "en_FI",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+358",
  },
  {
    iso2Code: "fr",
    name: "France",
    bookingEngineLocale: "en_FR",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+33",
  },
  {
    iso2Code: "gf",
    name: "French Guiana",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+594",
  },
  {
    iso2Code: "pf",
    name: "French Polynesia",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+689",
  },
  {
    iso2Code: "tf",
    name: "French Southern Territories",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+672",
  },
  {
    iso2Code: "ga",
    name: "Gabon",
    bookingEngineLocale: "en_GA",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+241",
  },
  {
    iso2Code: "gm",
    name: "Gambia",
    bookingEngineLocale: "en_GM",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+220",
  },
  {
    iso2Code: "ge",
    name: "Georgia",
    bookingEngineLocale: "en_GE",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+995",
  },
  {
    iso2Code: "de",
    name: "Germany",
    bookingEngineLocale: "en_DE",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+49",
  },
  {
    iso2Code: "gh",
    name: "Ghana",
    bookingEngineLocale: "en_GH",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+233",
  },
  {
    iso2Code: "gi",
    name: "Gibraltar",
    bookingEngineLocale: "en_GI",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+350",
  },
  {
    iso2Code: "gr",
    name: "Greece",
    bookingEngineLocale: "en_GR",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+30",
  },
  {
    iso2Code: "gl",
    name: "Greenland",
    bookingEngineLocale: "en_GL",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+299",
  },
  {
    iso2Code: "gd",
    name: "Grenada",
    bookingEngineLocale: "en_GD",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+1473",
  },
  {
    iso2Code: "gp",
    name: "Guadeloupe",
    bookingEngineLocale: "en_GP",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+590",
  },
  {
    iso2Code: "gu",
    name: "Guam",
    bookingEngineLocale: "en_GU",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+1671",
  },
  {
    iso2Code: "gt",
    name: "Guatemala",
    bookingEngineLocale: "en_GT",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+502",
  },
  {
    iso2Code: "gg",
    name: "Guernsey",
    bookingEngineLocale: "en_GG",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+44",
  },
  {
    iso2Code: "gn",
    name: "Guinea",
    bookingEngineLocale: "en_GN",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+224",
  },
  {
    iso2Code: "gw",
    name: "Guinea-Bissau",
    bookingEngineLocale: "en_GW",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+245",
  },
  {
    iso2Code: "gy",
    name: "Guyana",
    bookingEngineLocale: "en_GY",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+592",
  },
  {
    iso2Code: "ht",
    name: "Haiti",
    bookingEngineLocale: "en_HT",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+509",
  },
  {
    iso2Code: "hm",
    name: "Heard Island and Mcdonald Islands",
    bookingEngineLocale: "en_HM",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+0",
  },
  {
    iso2Code: "hn",
    name: "Honduras",
    bookingEngineLocale: "en_HN",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+504",
  },
  {
    iso2Code: "hk",
    name: "Hong Kong",
    bookingEngineLocale: "en_HK",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+852",
  },
  {
    iso2Code: "hu",
    name: "Hungary",
    bookingEngineLocale: "en_HU",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+36",
  },
  {
    iso2Code: "is",
    name: "Iceland",
    bookingEngineLocale: "en_IS",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+354",
  },
  {
    iso2Code: "in",
    name: "India",
    bookingEngineLocale: "en_IN",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+91",
  },
  {
    iso2Code: "id",
    name: "Indonesia",
    bookingEngineLocale: "en_ID",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+62",
  },
  {
    iso2Code: "ir",
    name: "Iran",
    bookingEngineLocale: "en_IR",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+98",
  },
  {
    iso2Code: "iq",
    name: "Iraq",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+964",
  },
  {
    iso2Code: "im",
    name: "Isle of Man",
    bookingEngineLocale: "en_IM",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+44",
  },
  {
    iso2Code: "il",
    name: "Israel",
    bookingEngineLocale: "en_IL",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+972",
  },
  {
    iso2Code: "it",
    name: "Italy",
    bookingEngineLocale: "en_IT",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+39",
  },
  {
    iso2Code: "ci",
    name: "Ivory Coast",
    bookingEngineLocale: "en_CI",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+225",
  },
  {
    iso2Code: "jm",
    name: "Jamaica",
    bookingEngineLocale: "en_JM",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+1876",
  },
  {
    iso2Code: "jp",
    name: "Japan",
    bookingEngineLocale: "en_JP",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+81",
  },
  {
    iso2Code: "je",
    name: "Jersey",
    bookingEngineLocale: "en_JE",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+44",
  },
  {
    iso2Code: "jo",
    name: "Jordan",
    bookingEngineLocale: "en_JO",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+962",
  },
  {
    iso2Code: "kz",
    name: "Kazakhstan",
    bookingEngineLocale: "en_KZ",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+7",
  },
  {
    iso2Code: "ke",
    name: "Kenya",
    bookingEngineLocale: "en_KE",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+254",
  },
  {
    iso2Code: "ki",
    name: "Kiribati",
    bookingEngineLocale: "en_KI",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+686",
  },
  {
    iso2Code: "xk",
    name: "Kosovo",
    bookingEngineLocale: "en_IE",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+381",
  },
  {
    iso2Code: "kw",
    name: "Kuwait",
    bookingEngineLocale: "en_KW",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+965",
  },
  {
    iso2Code: "kg",
    name: "Kyrgyzstan",
    bookingEngineLocale: "en_KG",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+996",
  },
  {
    iso2Code: "la",
    name: "Laos",
    bookingEngineLocale: "en_LA",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+856",
  },
  {
    iso2Code: "lv",
    name: "Latvia",
    bookingEngineLocale: "en_LV",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+371",
  },
  {
    iso2Code: "lb",
    name: "Lebanon",
    bookingEngineLocale: "en_LB",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+961",
  },
  {
    iso2Code: "ls",
    name: "Lesotho",
    bookingEngineLocale: "en_LS",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+266",
  },
  {
    iso2Code: "lr",
    name: "Liberia",
    bookingEngineLocale: "en_LR",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+231",
  },
  {
    iso2Code: "ly",
    name: "Libya",
    bookingEngineLocale: "en_LY",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+218",
  },
  {
    iso2Code: "li",
    name: "Liechtenstein",
    bookingEngineLocale: "en_LI",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+423",
  },
  {
    iso2Code: "lt",
    name: "Lithuania",
    bookingEngineLocale: "en_LT",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+370",
  },
  {
    iso2Code: "lu",
    name: "Luxembourg",
    bookingEngineLocale: "en_LU",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+352",
  },
  {
    iso2Code: "mo",
    name: "Macao",
    bookingEngineLocale: "en_MO",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+853",
  },
  {
    iso2Code: "mk",
    name: "Macedonia",
    bookingEngineLocale: "en_MK",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+389",
  },
  {
    iso2Code: "mg",
    name: "Madagascar",
    bookingEngineLocale: "en_MG",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+261",
  },
  {
    iso2Code: "mw",
    name: "Malawi",
    bookingEngineLocale: "en_MW",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+265",
  },
  {
    iso2Code: "my",
    name: "Malaysia",
    bookingEngineLocale: "en_MY",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+60",
  },
  {
    iso2Code: "mv",
    name: "Maldives",
    bookingEngineLocale: "en_MV",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+960",
  },
  {
    iso2Code: "ml",
    name: "Mali",
    bookingEngineLocale: "en_ML",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+223",
  },
  {
    iso2Code: "mt",
    name: "Malta",
    bookingEngineLocale: "en_MT",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+356",
  },
  {
    iso2Code: "mh",
    name: "Marshall Islands",
    bookingEngineLocale: "en_MH",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+692",
  },
  {
    iso2Code: "mq",
    name: "Martinique",
    bookingEngineLocale: "en_MQ",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+596",
  },
  {
    iso2Code: "mr",
    name: "Mauritania",
    bookingEngineLocale: "en_MR",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+222",
  },
  {
    iso2Code: "mu",
    name: "Mauritius",
    bookingEngineLocale: "en_MU",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+230",
  },
  {
    iso2Code: "yt",
    name: "Mayotte",
    bookingEngineLocale: "en_YT",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+262",
  },
  {
    iso2Code: "mx",
    name: "Mexico",
    bookingEngineLocale: "en_MX",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+52",
  },
  {
    iso2Code: "fm",
    name: "Micronesia",
    bookingEngineLocale: "en_FM",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+691",
  },
  {
    iso2Code: "md",
    name: "Moldova",
    bookingEngineLocale: "en_MD",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+373",
  },
  {
    iso2Code: "mc",
    name: "Monaco",
    bookingEngineLocale: "en_MC",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+377",
  },
  {
    iso2Code: "mn",
    name: "Mongolia",
    bookingEngineLocale: "en_MN",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+976",
  },
  {
    iso2Code: "me",
    name: "Montenegro",
    bookingEngineLocale: "en_ME",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+382",
  },
  {
    iso2Code: "ms",
    name: "Montserrat",
    bookingEngineLocale: "en_MS",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+1664",
  },
  {
    iso2Code: "ma",
    name: "Morocco",
    bookingEngineLocale: "en_MA",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+212",
  },
  {
    iso2Code: "mz",
    name: "Mozambique",
    bookingEngineLocale: "en_MZ",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+258",
  },
  {
    iso2Code: "mm",
    name: "Myanmar (Burma)",
    bookingEngineLocale: "en_MM",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+95",
  },
  {
    iso2Code: "na",
    name: "Namibia",
    bookingEngineLocale: "en_NA",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+264",
  },
  {
    iso2Code: "nr",
    name: "Nauru",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+674",
  },
  {
    iso2Code: "np",
    name: "Nepal",
    bookingEngineLocale: "en_NP",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+977",
  },
  {
    iso2Code: "nl",
    name: "Netherlands",
    bookingEngineLocale: "en_NL",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+31",
  },
  {
    iso2Code: "an",
    name: "Netherlands Antilles",
    bookingEngineLocale: "en_AN",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+599",
  },
  {
    iso2Code: "nc",
    name: "New Caledonia",
    bookingEngineLocale: "en_NC",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+687",
  },
  {
    iso2Code: "ni",
    name: "Nicaragua",
    bookingEngineLocale: "en_NI",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+505",
  },
  {
    iso2Code: "ne",
    name: "Niger",
    bookingEngineLocale: "en_NE",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+227",
  },
  {
    iso2Code: "ng",
    name: "Nigeria",
    bookingEngineLocale: "en_NG",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+234",
  },
  {
    iso2Code: "nu",
    name: "Niue",
    bookingEngineLocale: "en_NU",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+683",
  },
  {
    iso2Code: "nf",
    name: "Norfolk Island",
    bookingEngineLocale: "en_NF",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+672",
  },
  {
    iso2Code: "kp",
    name: "North Korea",
    bookingEngineLocale: "en_KP",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+850",
  },
  {
    iso2Code: "mp",
    name: "Northern Mariana Islands",
    bookingEngineLocale: "en_MP",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+1670",
  },
  {
    iso2Code: "no",
    name: "Norway",
    bookingEngineLocale: "en_NO",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+47",
  },
  {
    iso2Code: "om",
    name: "Oman",
    bookingEngineLocale: "en_OM",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+968",
  },
  {
    iso2Code: "pk",
    name: "Pakistan",
    bookingEngineLocale: "en_PK",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+92",
  },
  {
    iso2Code: "pw",
    name: "Palau",
    bookingEngineLocale: "en_PW",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+680",
  },
  {
    iso2Code: "ps",
    name: "Palestinian Territory",
    bookingEngineLocale: "en_PS",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+970",
  },
  {
    iso2Code: "pa",
    name: "Panama",
    bookingEngineLocale: "en_PA",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+507",
  },
  {
    iso2Code: "pg",
    name: "Papua New Guinea",
    bookingEngineLocale: "en_PG",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+675",
  },
  {
    iso2Code: "py",
    name: "Paraguay",
    bookingEngineLocale: "en_PY",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+595",
  },
  {
    iso2Code: "pe",
    name: "Peru",
    bookingEngineLocale: "en_PE",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+51",
  },
  {
    iso2Code: "ph",
    name: "Philippines",
    bookingEngineLocale: "en_PH",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+63",
  },
  {
    iso2Code: "pn",
    name: "Pitcairn",
    bookingEngineLocale: "en_PN",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+870",
  },
  {
    iso2Code: "pl",
    name: "Poland",
    bookingEngineLocale: "en_PL",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+48",
  },
  {
    iso2Code: "pt",
    name: "Portugal",
    bookingEngineLocale: "en_PT",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+351",
  },
  {
    iso2Code: "pr",
    name: "Puerto Rico",
    bookingEngineLocale: "en_PR",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+1",
  },
  {
    iso2Code: "qa",
    name: "Qatar",
    bookingEngineLocale: "en_QA",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+974",
  },
  {
    iso2Code: "cg",
    name: "Republic of the Congo",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+242",
  },
  {
    iso2Code: "re",
    name: "Reunion",
    bookingEngineLocale: "en_RE",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+262",
  },
  {
    iso2Code: "ro",
    name: "Romania",
    bookingEngineLocale: "en_RO",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+40",
  },
  {
    iso2Code: "ru",
    name: "Russia",
    bookingEngineLocale: "en_RU",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+7",
  },
  {
    iso2Code: "rw",
    name: "Rwanda",
    bookingEngineLocale: "en_RW",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+250",
  },
  {
    iso2Code: "bl",
    name: "Saint Barthelemy",
    bookingEngineLocale: "en_BL",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+590",
  },
  {
    iso2Code: "sh",
    name: "Saint Helena",
    bookingEngineLocale: "en_SH",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+290",
  },
  {
    iso2Code: "kn",
    name: "Saint Kitts and Nevis",
    bookingEngineLocale: "en_KN",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+1869",
  },
  {
    iso2Code: "lc",
    name: "Saint Lucia",
    bookingEngineLocale: "en_LC",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+1758",
  },
  {
    iso2Code: "mf",
    name: "Saint Martin",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+1599",
  },
  {
    iso2Code: "pm",
    name: "Saint Pierre and Miquelon",
    bookingEngineLocale: "en_PM",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+508",
  },
  {
    iso2Code: "vc",
    name: "Saint Vincent and the Grenadines",
    bookingEngineLocale: "en_VC",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+1784",
  },
  {
    iso2Code: "ws",
    name: "Samoa",
    bookingEngineLocale: "en_WS",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+685",
  },
  {
    iso2Code: "sm",
    name: "San Marino",
    bookingEngineLocale: "en_SM",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+378",
  },
  {
    iso2Code: "st",
    name: "Sao Tome and Principe",
    bookingEngineLocale: "en_ST",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+239",
  },
  {
    iso2Code: "sa",
    name: "Saudi Arabia",
    bookingEngineLocale: "en_SA",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+966",
  },
  {
    iso2Code: "sn",
    name: "Senegal",
    bookingEngineLocale: "en_SN",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+221",
  },
  {
    iso2Code: "rs",
    name: "Serbia",
    bookingEngineLocale: "en_RS",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+381",
  },
  {
    iso2Code: "sc",
    name: "Seychelles",
    bookingEngineLocale: "en_SC",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+248",
  },
  {
    iso2Code: "sl",
    name: "Sierra Leone",
    bookingEngineLocale: "en_SL",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+232",
  },
  {
    iso2Code: "sg",
    name: "Singapore",
    bookingEngineLocale: "en_SG",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+65",
  },
  {
    iso2Code: "sx",
    name: "Sint Maarten",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+1-721",
  },
  {
    iso2Code: "sk",
    name: "Slovakia",
    bookingEngineLocale: "en_SK",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+421",
  },
  {
    iso2Code: "si",
    name: "Slovenia",
    bookingEngineLocale: "en_SI",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+386",
  },
  {
    iso2Code: "sb",
    name: "Solomon Islands",
    bookingEngineLocale: "en_SB",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+677",
  },
  {
    iso2Code: "so",
    name: "Somalia",
    bookingEngineLocale: "en_SO",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+252",
  },
  {
    iso2Code: "za",
    name: "South Africa",
    bookingEngineLocale: "za_ZA",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+27",
  },
  {
    iso2Code: "gs",
    name: "South Georgia",
    bookingEngineLocale: "en_GS",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+995",
  },
  {
    iso2Code: "kr",
    name: "South Korea",
    bookingEngineLocale: "en_KR",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+82",
  },
  {
    iso2Code: "ss",
    name: "South Sudan",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+211",
  },
  {
    iso2Code: "es",
    name: "Spain",
    bookingEngineLocale: "en_ES",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+34",
  },
  {
    iso2Code: "lk",
    name: "Sri Lanka",
    bookingEngineLocale: "en_LK",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+94",
  },
  {
    iso2Code: "sd",
    name: "Sudan",
    bookingEngineLocale: "en_SD",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+249",
  },
  {
    iso2Code: "sr",
    name: "Suriname",
    bookingEngineLocale: "en_SR",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+597",
  },
  {
    iso2Code: "sj",
    name: "Svalbard and Jan Mayen",
    bookingEngineLocale: "en_SJ",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+47",
  },
  {
    iso2Code: "se",
    name: "Sweden",
    bookingEngineLocale: "en_SE",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+46",
  },
  {
    iso2Code: "sy",
    name: "Syria",
    bookingEngineLocale: "en_SY",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+963",
  },
  {
    iso2Code: "tw",
    name: "Taiwan",
    bookingEngineLocale: "en_TW",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+886",
  },
  {
    iso2Code: "tj",
    name: "Tajikistan",
    bookingEngineLocale: "en_TJ",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+992",
  },
  {
    iso2Code: "tz",
    name: "Tanzania",
    bookingEngineLocale: "en_TZ",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+255",
  },
  {
    iso2Code: "th",
    name: "Thailand",
    bookingEngineLocale: "en_TH",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+66",
  },
  {
    iso2Code: "tl",
    name: "Timor-Leste",
    bookingEngineLocale: "en_TL",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+670",
  },
  {
    iso2Code: "tg",
    name: "Togo",
    bookingEngineLocale: "en_TG",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+228",
  },
  {
    iso2Code: "tk",
    name: "Tokelau",
    bookingEngineLocale: "en_TK",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+690",
  },
  {
    iso2Code: "to",
    name: "Tonga",
    bookingEngineLocale: "en_TO",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+676",
  },
  {
    iso2Code: "tt",
    name: "Trinidad and Tobago",
    bookingEngineLocale: "en_TT",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+1868",
  },
  {
    iso2Code: "tn",
    name: "Tunisia",
    bookingEngineLocale: "en_TN",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+216",
  },
  {
    iso2Code: "tr",
    name: "Turkey",
    bookingEngineLocale: "en_TR",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+90",
  },
  {
    iso2Code: "tm",
    name: "Turkmenistan",
    bookingEngineLocale: "en_TM",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+993",
  },
  {
    iso2Code: "tc",
    name: "Turks and Caicos Islands",
    bookingEngineLocale: "en_TC",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+1649",
  },
  {
    iso2Code: "tv",
    name: "Tuvalu",
    bookingEngineLocale: "en_TV",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+688",
  },
  {
    iso2Code: "vi",
    name: "U.S. Virgin Islands",
    bookingEngineLocale: "en_VI",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+1340",
  },
  {
    iso2Code: "ug",
    name: "Uganda",
    bookingEngineLocale: "en_UG",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+256",
  },
  {
    iso2Code: "ua",
    name: "Ukraine",
    bookingEngineLocale: "en_UA",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+380",
  },
  {
    iso2Code: "ae",
    name: "United Arab Emirates",
    bookingEngineLocale: "en_AE",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+971",
  },
  {
    iso2Code: "um",
    name: "United States Minor Outlying Islands",
    bookingEngineLocale: "en_US",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+246",
  },
  {
    iso2Code: "uy",
    name: "Uruguay",
    bookingEngineLocale: "en_UY",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+598",
  },
  {
    iso2Code: "uz",
    name: "Uzbekistan",
    bookingEngineLocale: "en_UZ",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+998",
  },
  {
    iso2Code: "vu",
    name: "Vanuatu",
    bookingEngineLocale: "en_VU",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+678",
  },
  {
    iso2Code: "va",
    name: "Vatican City",
    bookingEngineLocale: "en_VA",
    marketingRegion: MarketingRegion.OTHER,
    phoneCode: "+39",
  },
  {
    iso2Code: "ve",
    name: "Venezuela",
    bookingEngineLocale: "en_VE",
    marketingRegion: MarketingRegion.NA,
    phoneCode: "+58",
  },
  {
    iso2Code: "vn",
    name: "Vietnam",
    bookingEngineLocale: "en_VN",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+84",
  },
  {
    iso2Code: "wf",
    name: "Wallis and Futuna",
    bookingEngineLocale: "en_WF",
    marketingRegion: MarketingRegion.APAC,
    phoneCode: "+681",
  },
  {
    iso2Code: "eh",
    name: "Western Sahara",
    bookingEngineLocale: "en_EH",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+212",
  },
  {
    iso2Code: "ye",
    name: "Yemen",
    bookingEngineLocale: "en_YE",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+967",
  },
  {
    iso2Code: "zm",
    name: "Zambia",
    bookingEngineLocale: "en_ZM",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+260",
  },
  {
    iso2Code: "zw",
    name: "Zimbabwe",
    bookingEngineLocale: "en_ZW",
    marketingRegion: MarketingRegion.EMEA,
    phoneCode: "+263",
  },
];
