<template>
  <App>
    <header>
      <NebulaHeader />
    </header>
    <Nuxt />
    <footer>
      <NebulaFooter />
    </footer>
  </App>
</template>

<script lang="ts">
import Vue from "vue";
import App from "~/components/App/App.vue";
import Header from "~/components/Header/Header.vue";
import Footer from "~/components/Footer/Footer.vue";

export default Vue.extend({
  name: "DefaultLayout",
  components: {
    App,
    NebulaHeader: Header,
    NebulaFooter: Footer,
  },
  mounted() {
    this.$initLivePreview();
  },
});
</script>
