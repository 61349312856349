<template>
  <main>
    <PageBanner v-bind="errorBanner" />
    <div class="l-container u-margin-top--2">
      <Breadcrumb
        description="desc"
        :items="breadcrumbs"
        class="u-margin-bottom--2"
      />
      <article class="l-grid">
        <div class="l-grid__cell l-grid__cell--6-col">
          <h3 class="u-margin-top--0">{{ errorMessage }}</h3>
          <p class="lead">Check out the links below and get back on track.</p>
          <div class="u-margin-top--2">
            <Link class="button button--primary" href="/search"
              >View all trips</Link
            >
            <Link class="button button--primary" href="/latest-deals"
              >See our latest offers</Link
            >
          </div>
        </div>
        <div class="l-grid__cell l-grid__cell--6-col">
          <Imagery
            alt="Girl sitting backwards on camel"
            :width="250"
            :height="200"
            type="static"
            lazy-src="/files/images/illustrations/llama.svg"
          />
        </div>
      </article>
    </div>
  </main>
</template>

<script lang="ts">
import Vue from "vue";
import { MetaInfo } from "vue-meta";
import Breadcrumb from "atlas/src/components/Breadcrumb/Breadcrumb.vue";
import Imagery from "atlas/src/components/Imagery/Imagery.vue";
import PageBanner from "~/components/PageBanner/PageBanner.vue";
import Link from "~/components/Link/Link.vue";
import { PageBannerProps } from "~~/components/PageBanner/Type";

export default Vue.extend({
  name: "ErrorLayout",
  components: {
    PageBanner,
    Link,
    Breadcrumb,
    Imagery,
  },
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  head(): MetaInfo {
    return {
      title: this.errorTitle,
    };
  },
  computed: {
    breadcrumbs(): { title: string; href: string }[] {
      return [
        {
          title: "Home",
          href: `/${this.$i18n.locale}`,
        },
        {
          title: this.errorTitle,
          href: "/#",
        },
      ];
    },
    errorBanner(): PageBannerProps {
      return {
        title: this.errorTitle,
        image: {
          src: "/files/images/banners/404-banner.jpg",
          alt: "banner image",
          width: 1920,
          height: 500,
        },
      };
    },
    errorTitle(): string {
      switch (this.error.statusCode.toString()) {
        case "404":
          return "404 - Page not found";
        case "503":
          return "Service Temporarily Unavailable";
        default:
          return "Internal server error";
      }
    },
    errorMessage(): string {
      switch (this.error.statusCode.toString()) {
        case "404":
          return "Looks like you took a wrong turn.";
        case "503":
          return "Service is temporarily unavailable";
        default:
          return "Internal server error occurred";
      }
    },
  },
  mounted() {
    this.$initLivePreview();
  },
});
</script>
