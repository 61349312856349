<template>
  <App>
    <header>
      <NebulaHeader />
    </header>
    <Nuxt />
    <footer>
      <WTPH data-cy="wtph" />
      <NebulaFooter />
      <RegionDetector
        :currentLocale="$i18n.localeProperties"
        :preferredRegion="preferredRegion"
        :originRegionName="originRegion.name"
        :originCountry="originCountry"
        :originCountryName="originCountryName"
        :originRegion="originRegion"
        @goto="$store.dispatch('user/goToRegion')"
        @dismiss="$store.dispatch('user/preferCurrentRegion')"
      />
      <client-only>
        <LiveAgent />
      </client-only>
    </footer>
  </App>
</template>

<script lang="ts">
import Vue from "vue";
import App from "~/components/App/App.vue";
import Header from "~/components/Header/Header.vue";
import WTPH from "~/components/WTPH/WTPH.vue";
import Footer from "~/components/Footer/Footer.vue";
import RegionDetector from "~/components/RegionDetector/RegionDetector.vue";
import { getLocaleByCountry } from "~/lib/utils/locale/getLocaleByCountry";
import Locale from "~/lib/types/Locale";

export default Vue.extend({
  name: "DefaultLayout",
  components: {
    App,
    WTPH,
    NebulaHeader: Header,
    NebulaFooter: Footer,
    RegionDetector,
    LiveAgent: () =>
      process.client
        ? import("~/components/LiveAgent/LiveAgent.vue")
        : undefined,
  },
  computed: {
    preferredRegion(): string {
      return this.$store.getters["user/preferredRegion"];
    },
    originCountry(): string {
      return this.$store.getters["user/originCountry"];
    },
    originCountryName(): string {
      return this.$store.getters["user/originCountryName"];
    },
    originRegion(): Locale {
      return getLocaleByCountry(this.originCountry);
    },
  },
});
</script>
